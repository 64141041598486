import { pick } from 'lodash';
import { MODAL_TYPE_EDIT_POST } from '../components/modals/post-create-modal/post-edit-modal-type';
import sanitizeContent from '../services/sanitize-content';
import {
  extractHashtagsFromContent,
  extractMentionsFromContent,
} from '@wix/communities-forum-client-commons';

import { POST_UPDATE } from '../components/messages/message-types';
import { showMessage } from '../../common/messages/framework/store/message-actions';
import { navigateWithinForum } from '../../common/actions/navigate-within-forum';
import { getRouteParams } from '../../common/router/router-selectors';
import { closeModal, openModal } from '../../common/modals/framework/store/modal-actions';
import { createPromisifiedAction } from '../../common/actions-promisifier/create-promisified-action';
import { isExternalHookResolve } from '../../common/services/external-hooks';
import { createExternalHookRejectHandler } from '../../common/services/external-hooks-flow';
import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../reducers/app-dispatch';
import { Dispatch, RootState } from '../types/store-types';
import { CommonRequest } from '../types';
import { userEventsPostCaptchaResolved, userEventsPostCaptchaShown } from './user-events';
import { getCaptchaToken } from '../services/get-captcha-token';
import { MODAL_TYPE_POST_PENDING_APPROVAL } from '../components/modals/post-pending-approval-modal/post-pending-approval-modal-type';

interface Post {
  _id: string;
  categoryId: string;
  content: any;
  title: string;
}

export interface UpdatePostSuccess extends Post {
  postId: string;
  postType: string;
  draft?: boolean;
}

export const UPDATE_POST_REQUEST = 'post/UPDATE_REQUEST';
export const UPDATE_POST_SUCCESS = 'post/UPDATE_SUCCESS';
export const UPDATE_POST_FAILURE = 'post/UPDATE_FAILURE';
export const CAPTCHA_ERROR_CODE = 15;

export const updatePostRequest = createAction(UPDATE_POST_REQUEST);
export const updatePostSuccess = createAction(
  UPDATE_POST_SUCCESS,
  (payload: UpdatePostSuccess, meta: { isCaptchaUsed: boolean }) => {
    return { payload, meta };
  },
);
export const updatePostFailure = createAction(UPDATE_POST_FAILURE, (meta) => ({
  payload: undefined,
  meta,
}));

const PROPS = ['title', 'content'];

export function updatePost(post: Post, token?: string) {
  return (
    dispatch: AppDispatch,
    getState: () => RootState,
    { request }: { request: CommonRequest },
  ) => {
    dispatch(updatePostRequest());

    const isCaptchaUsed = !!token;
    const tokenQuery = isCaptchaUsed ? `token=${token}` : '';

    const updatedPostSanitized = sanitizeContent({ content: '', ...pick<Post>(post, PROPS) });
    const promise = request.patch<UpdatePostSuccess>(`/posts/${post._id}?${tokenQuery}`, {
      ...updatedPostSanitized,
      hashtags: extractHashtagsFromContent(updatedPostSanitized.content),
      mentions: extractMentionsFromContent(updatedPostSanitized.content),
    });

    return promise
      .then(
        (updatedPost) => {
          dispatch(updatePostSuccess(updatedPost, { isCaptchaUsed }));
          const params = getRouteParams(getState());
          dispatch(closeModal({ type: MODAL_TYPE_EDIT_POST, resolve: false }));

          if (updatedPost.draft) {
            dispatch(
              openModal(MODAL_TYPE_POST_PENDING_APPROVAL, {
                postId: post._id,
                categoryId: post.categoryId,
                isEdit: true,
              }),
            );
          } else {
            dispatch(showMessage(POST_UPDATE));
          }

          dispatch(navigateWithinForum(`/${params.categorySlug}/${params.postSlug}`));
        },
        (response) => dispatch(updatePostFailure(response)),
      )
      .then(() => promise);
  };
}

export const updatePostPromisified = (onBeforePostUpdateHook: any) =>
  createPromisifiedAction(
    (post: Post) => {
      return async (
        dispatch: Dispatch,
        getState: () => RootState,
        { wixCodeApi }: { wixCodeApi: any },
      ) => {
        let promise = Promise.resolve();

        if (onBeforePostUpdateHook.hasHook()) {
          promise = promise.then(() =>
            onBeforePostUpdateHook.exec(post).catch(createExternalHookRejectHandler(dispatch)),
          );
        }

        let postToUse: Post;

        return promise
          .then((r: any) => {
            postToUse = isExternalHookResolve(r) ? r.payload || post : post;
            return dispatch(updatePost(postToUse));
          })
          .catch(async (response) => {
            if (response.data.errorCode !== CAPTCHA_ERROR_CODE) {
              return Promise.reject(response);
            }

            const token = await getCaptchaToken(dispatch, wixCodeApi);
            return token
              ? dispatch(updatePost(post, token))
              : // eslint-disable-next-line prefer-promise-reject-errors
                Promise.reject(response);
          });
      };
    },
    () => null,
    (response) => response.status,
  );
